const trans = {
  'Absence attack': 'Frånvaroattacker',
  'account.search.placeholder': 'Sök genom att skriva namn...',
  'accounts': 'Konton',
  'actions': 'Handlingar',
  'activateTestsInApp': 'Aktivera i app',
  'Add care provider for referral / consultation': 'Använd vårdgivar-id för remittering (t.ex. GSAPP)',
  'Add comment': 'Lägg till anteckning och signera',
  'Add note': 'Lägg till anteckning',
  'Add recommendation': 'Lägg till rekommendation',
  'Add summary': 'Lägg till anteckning',
  'Add-voucher': 'Ny kod',
  'add_referal': 'Lägg till',
  'add_sign_note_button': 'Lägg till / signera',
  'addComment': 'Lägg till en kommentar',
  'admin': 'Admin',
  'administrator': 'Adminstratör',
  'age': 'Ålder',
  'Aggressivness': ' Aggressivitet',
  'agreement': 'Jag accepterar',
  'agreement_1': 'Jag accepterar',
  'agreement_2': 'Användarvillkoren',
  'agreement_3': 'Sekretessvillkoren',
  'agreement_4': 'Forskningsvillkoren',
  'alcoholNoteRisk': 'Kvinnor som får 3 poäng eller högre dricker över rekommenderade gränser och har ökade risker att ta skada, medan män måste ha 4 poäng eller högre för detta. Källa: <i>Survey of Healthcare Experiences of Patients, 2008, VA Office of Quality and Performance.</i><br /><br />När punkterna alla är baserade på Q1 (med 0 poäng för Q2 och Q3), kan det antas att patienten dricker under rekommenderade gränser och vidare undersökning bör göras med resultat från de senaste månaderna för att bekräfta noggrannhet',
  'Alkohol (Audit-C) och rökning': 'Alcohol (Audit-C) and smoking',
  'all': 'Alla',
  'All data related to this user will be erased. This action can be undone.': 'Alla data relaterad till denna användare kommer att raderas. Detta kan reverseras senare.',
  'allAccounts': 'Alla konton',
  'Allmänt': 'General',
  'allPatients': 'Alla patienter',
  'Alzheimer\'s disease with cerebrovascular injury - AD + VaD': 'Alzheimers sjukdom med cerebrovaskulär skada - AD + VaD ',
  'Alzheimer\'s disease, mild dementia - Early onset': 'Alzheimers sjukdom, mild demens - Tidig debut',
  'Alzheimer\'s disease, mild-moderate dementia - Late onset': 'Alzheimers sjukdom, mild-medelsvår demens - Sen debut',
  'Alzheimer\'s disease, prodromal - Amnestic MCI': 'Alzheimers sjukdom, prodromal - Amnestisk MCI',
  'amnesis': 'Anamnes',
  'anamnesis': 'Anamnes',
  'Anamnesis': 'Anamnes',
  'anamnesisFlex': 'Anamnes',
  'anamnesisFlexNew': 'Anamnes',
  'anamnesisFlexNew.categories.habits': 'Levnadsvanor',
  'anamnesisFlexNew.categories.medical': 'Sjukdomshistorik',
  'anamnesisFlexNew.categories.screening': 'Screening',
  'anamnesisFlexNew.categories.social': 'Livsstil & Symtom',
  'anamnesisSettings': 'Sektioner i Anamnes',
  'and': 'och',
  'Android_version': 'Android app',
  'Answer': 'Patientens svar',
  'answer': 'Svar',
  'Answer was not provided!': ' Inget svar angivet',
  'answer_skipped_idk': 'Hoppat över eftersom de svarade "Jag vet inte" på den förra frågan.',
  'answer_skipped_no': 'Hoppat över eftersom de svarade "Nej" på den förra frågan.',
  'Answered by': 'Besvarats av',
  'answered_as_not_relevant': 'Svar: Vet ej / ej relevant',
  'Anxiety': ' Ångest ',
  'Appetite control': ' Aptitkontroll',
  'appointment-with': 'Möte med',
  'appointments': 'Möten',
  'April': 'April',
  'Archive user': 'Arkivera användare',
  'Are you sure you want to archive this user?': 'Är du säker på att du vill arkivera användaren?',
  'Are you sure you want to completely delete this user?': 'Är du säker på att du vill radera denna användare?',
  'Are you sure you want to delete this user?': 'Är du säker på att du vill radera användaren?',
  'Are you sure you want to restore this user?': 'Är du säker på att du vill återställa användaren?',
  'assistant_nurse': 'Undersköterska',
  'Associated symptoms': 'Associerade Symtom',
  'At': 'Vid',
  'attempt': 'Uppgift',
  'Attempt': 'task',
  'atTime': 'Klockan',
  'August': 'Augusti',
  'availability': 'Tillgänglighet',
  'Availability': 'Tillgänglighet',
  'availability-page.avails': 'Tillgängliga tider',
  'availability-page.text': 'Välj plusikonen för att ange din tillgänglighet för vald dag. Om du tar bort tillgängligheten för en plats, som redan har ett patientmöte inbokat, kommer inte patientens möte att tas bort. Bokade patientmöten måste tas bort manuellt.',
  'availability-page.title': 'Mina möten',
  'available times': 'Tillgängliga tider',
  'Average of similar healthy patients': 'Genomsnitt för liknande friska patienter',
  'back': 'Tillbaka',
  'Balance': ' Balans',
  'bankId': 'Bank-ID',
  'basicInformation': 'Generell information',
  'Behaviour': 'Beteende ',
  'birthDate': 'Födelsedatum',
  'Bladder control': ' Blåskontroll',
  'Body perception': ' Kroppsuppfattning',
  'booked meetings': 'Bokade möten',
  'calendar': 'Kalender',
  'Call': 'Ring',
  'Call now': 'Ring nu',
  'Cam': 'Kam',
  'cancel': 'Avbryt',
  'Care provider with access to patient account': 'Vårdgivare med åtkomst till patientkonto',
  'careGiver': 'Vårdpersonal',
  'Category': 'Kategori',
  'change': 'Ändring',
  'child': 'Barn',
  'clear': 'återställ',
  'clinical_pharmacologist': 'Klinisk farmakolog',
  'close-panel': 'Stäng panel',
  'coding test hint': '1 poäng per 10 korrekta svar',
  'cognitive': 'Kognitivt test (GSCT)',
  'Cognitive': 'Kognitivt test (GSCT)',
  'Cognitive Disease': 'Kognitiv sjukdom',
  'Cognitive test': 'Kognitivt test',
  'Cognitive test completed': 'Genomfört kognitivt test',
  'Collapse': 'Minimera',
  'common_error_message': 'An unknown error occurred.',
  'Complete': 'Färdig',
  'Completed': 'Färdig',
  'Computability': ' Räkneförmåga',
  'Confirm': 'Bekräfta',
  'Confirm new password': 'Bekräfta nytt lösenord',
  'Confirm password': 'Bekräfta lösenord',
  'Confusion episodes': ' Förvirringsepisoder',
  'Consent obtained': 'Samtycke erhållet',
  'Construction': ' Konstruktion',
  'contacts': 'Kontakter',
  'contacts.heading.clinicalAssessmentsAndEvidence': 'Kliniska bedömningar & evidens',
  'contacts.heading.otherInquiries': 'Övriga ärenden',
  'contacts.heading.patientAdministrationAndAccounts': 'Administration & patientdata',
  'contacts.heading.technologyProductPatientData': 'Teknik, produkt & patientdata',
  'contacts.position.chiefMedicalOfficer': 'Kliniskt ansvarig läkare',
  'contacts.position.headOfClinicalOperationsAndImplementations': 'Ansvarig för implementeringar',
  'contacts.position.headOfTechnologyAndProductDevelopment': 'Ansvarig för teknik och produkt',
  'contactSupport.btn': 'Kontakta support',
  'contactSupport.text': 'Kontakta oss och vi kommer återkomma så snart som möjligt',
  'Correct': 'Korrekt',
  'country': 'Land',
  'country-sv': 'Sweden',
  'country-us': 'USA',
  'country-zh': 'China',
  'Course': 'Symtomförlopp',
  'Create': 'Skapa',
  'create_fm': 'Skapa konto för at genomföra Symtomenkät',
  'createCareGiveAccount': 'Skapa konto för vårdpersonal',
  'createdAt': 'Registreringsdatum',
  'createNewAccount': 'Skapa konto',
  'createNewAccountSuccessMessage': 'Ett konto är skapat',
  'createNewPatient': 'Skapa patient',
  'createNewPatientSuccessMessage': 'Du har skapat en ny patient',
  'da': 'Danish',
  'date': 'Datum',
  'Date answered': 'Datum besvarat',
  'dateAdded': 'Datum tillagd',
  'dateScheduled': 'Schemalagt datum',
  'Day': 'Dag',
  'December': 'December',
  'Delete user': 'Radera användare',
  'deleteAccount': 'Arkivera konto',
  'deletedAccounts': 'Arkiverade konton',
  'deletedPatients': 'Arkiverade patienter',
  'deletedPatients.counter.plural': 'Arkiverade patienter',
  'deletedPatients.counter.singular': 'Arkiverad patient',
  'deletePatient': 'Arkivera patient',
  'Delusions': ' Vanföreställningar',
  'Dementia': 'Demens',
  'Depression': ' Depression ',
  'deselect-all-and-save': 'Avmarkera allt och spara',
  'Details': 'Detaljer',
  'diagnosis': 'Diagnos',
  'diastolic-pressure': 'Mitt vanliga diastoliska tryck',
  'doctor': 'Läkare',
  'documents': 'Stöttande dokument',
  'documents.researchAndEvidence': 'Forskning och evidens',
  'Download PDF': 'Ladda ned PDF',
  'download_pdf_report': 'PDF-rapport',
  'downloaded': 'nedladdad',
  'downloadReportModalBtn': 'Ladda ned',
  'Downloads': 'Nedladdningar',
  'Draw': 'Rita',
  'Duration in years': 'Symtom varaktighet (år)',
  'edit history': 'Editeringshistorik',
  'Edit transcription': 'Redigera transkription',
  'Edit-answer': 'Ändra svar',
  'editAccount': 'Ändra konto',
  'editAccountSuccessMessage': 'Ändringar sparade',
  'editor': 'Redigerare',
  'editPatient': 'Ändra patient',
  'editPatientDetails': 'Administrera',
  'editPatientSuccessMessage': 'Ändringar sparade',
  'ef': 'Exekutiva funktioner',
  'ef-description': 'Verkställande funktion avser din förmåga till högre orderfunktioner, såsom planering, organisering och utvärdering etc.',
  'email': 'E-post',
  'Email': 'E-postadress',
  'emailInstructions': 'Emailinstruktioner',
  'emailLoginCodeInstructions': 'Patients login kod',
  'emailLoginInstructions': 'Emailinstruktioner och inloggningsuppgifter',
  'emailNote': 'Notera: Säkerställ att emailkorrespondens med patienten är godkänd',
  'emailSettings': 'Email settings',
  'Emotional Instability': ' Emotionell Instabilitet',
  'Emotional variation': 'Emotionell Variation',
  'en': 'English',
  'endocrinologist': 'Endokrinolog',
  'Enter-code': 'Enter code',
  'EnterRelativeEmail': 'Vänligen skriv in e-post till den du vill skicka en inbjudan',
  'EnterRelativePhoneNumber': 'Vänligen skriv in mobilnummer till den du vill skicka en inbjudan',
  'error_confirm': 'Lösenorden måste överenstämma',
  'error_password': 'Lösenordet måste innehålla minst 8 tecken samt speciella symboler (såsom ! och ?)',
  'errors.loginForm.invalidPNRFormat': 'Felaktigt personnummer',
  'errors.loginForm.invalidPNRLength': 'Ange personnummer med 12 siffror',
  'excelReport': 'Ladda ned rapport som Excel',
  'Expect': 'Rätt svar',
  'familyFormNote1': 'För att använda Geras Solutions app och utföra Symtomenkäten behöver du skapa ett konto.',
  'familyFormNote2': ' Vi behöver ditt godkännande för att hantera din information nedan.',
  'familyMember': 'Anhörig',
  'familySurvey': 'Family Survey',
  'February': 'Februari',
  'Female': 'Kvinna',
  'female': 'Kvinna',
  'Field must not be empty': 'Detta fält är obligatoriskt',
  'File was generated. Please click button below to download it': 'File was generated. Please click button below to download it.',
  'Filter patients by name': 'Filtrera patienter efter namn',
  'filter-patients-form.gender.title': 'Filtrera efter kön',
  'filter-patients-form.reset-filter': 'Återställ filter till standard',
  'filter-patients-form.status.title': 'Filtrera patienter efter status',
  'filter.accounts.admins': 'Admins',
  'filter.accounts.all': 'Alla konton',
  'filter.accounts.doctors': 'Läkare',
  'filter.accounts.nurses': 'Sjuksköterskor',
  'filter.accounts.superAdmins': 'Super Admins',
  'filter.all': 'Alla patienter',
  'filter.appointments': 'Har kommande möten',
  'filter.cognitive': 'Slutfört test',
  'filter.month': 'Månad',
  'filter.recommendations': 'Har rekommendationer',
  'filter.week': 'Vecka',
  'filter.year': 'År',
  'filters': 'Filter',
  'Fine motor skills': ' Finmotorik',
  'First name': 'Förnamn',
  'firstName': 'Förnamn',
  'focusNoteRisk': 'Detta baseras på validerad ADHD-självrapporteringsskala (ASRS), där varje fråga har sina specifika bedömningskriterier.Fråga 1-3 ger 1 poäng vardera om patienten svarat "Ibland" eller mer ofta. Fråga 4-6 ger 1 poäng vardera om patienten svarat "Ofta" eller "Väldigt ofta". Vid en total poäng på 4 eller mer uppvisar patienten symtom som i hög grad överensstämmer med ADHD hos vuxna och motiverar vidare utredning.',
  'Forget your password?': 'Glömt ditt lösenord?',
  'Forgot Password': 'Glömt lösenord',
  'Friday': 'Fredag',
  'friend': 'Vän',
  'Frontal': 'Frontal',
  'Frontal temporal dementia, behavioral variant - FTDbv': 'Frontaltemporal demens, beteendevariant - FTDbv',
  'Fysisk hälsa': 'Somatic status',
  'gender': 'Kön',
  'generalInformation': 'Allmän',
  'Generating PDF file process starting...': 'Generering av PDF-fil påbörjad...',
  'geras_forget_pass': 'Byt ditt lösenord här',
  'Go to video call': 'Gå till videosamtalet',
  'greetingComponent.text': 'Sök, skapa eller följ en patients teststatus',
  'greetingComponent.title': 'Hej',
  'Hallucinations': ' Hallucinationer',
  'Hand on screen': 'Hand på skärmen',
  'Have you obtained verbal consent from the patient to create an account on their behalf?': 'Har du fått muntlig samtycke från patienten att skapa ett konto på patientens vägnar?',
  'HCP_version': 'Portal',
  'headerCreatePatient': 'Skapa ny patient',
  'headerCreatePatientSteps.step1': 'Patientinformation',
  'headerCreatePatientSteps.step2': 'Välj moment för patient',
  'headerCreatePatientSteps.step3': 'Skräddarsy anamnes för patienten',
  'heavy-alcohol': 'stark alkohol',
  'height': 'Längd',
  'hide preliminary results': 'Dölj preliminära resultat för patienter',
  'high': 'Hög',
  'home': 'Hem',
  'Humor': 'Humor ',
  'idk': 'Jag vet inte',
  'ieWarning': 'Den här funktionen är inte tillgänglig i din nuvarande webbläsare. Använd en nyare version av Edge, Firefox, Chrome eller Safari för att använda den här funktionen.',
  'If': 'Om',
  'image': 'Bild',
  'Incorrect': 'Felaktigt',
  'Indicative reference library': 'Vägledande referensbibliotek',
  'information_gathering': 'Information gathering',
  'initialQuestionnaire': 'Inledande frågor - Testa hjärnhälsan',
  'Initiative': 'Initiativ ',
  'Insight': 'Insikt ',
  'institution': 'Vårdgivare',
  'institutionSettings': 'Shared insitution',
  'iOS_version': 'iOS app',
  'January': 'Januari',
  'journalNotes': 'Journalanteckningar',
  'July': 'Juli',
  'June': 'Juni',
  'l': 'Språk',
  'l-description': 'Orientering avser din förmåga att lokalisera dig själv inom tid och omgivning.',
  'landingPage.tile.cta': 'Skapa Patient & Skicka Test',
  'landingPage.tile.heading.createPatient': 'Skapa ny patient',
  'landingPage.tile.heading.searchPatient': 'Sök efter patient',
  'landingPage.tile.searchInputPlaceholder': 'Namn, efternamn, personnummer…',
  'Language understanding': ' Språkförståelse',
  'Language variation': 'Språkvariation',
  'Last name': 'Efternamn',
  'Last Update': ' uppdatering',
  'lastName': 'Efternamn',
  'latest score': 'Senaste testresultat',
  'latest-patients': 'Tidigare patienter',
  'Leave call': 'Lämna samtalet',
  'Lewybodydemens - DLB': 'Lewybodydemens - DLB',
  'light-alcohol': 'svag alkohol',
  'Logging in...': 'Loggar in...',
  'Logic': 'Logik ',
  'Login': 'Logga in',
  'Login with': 'Logga in med',
  'loginCode.expires': 'Utgår',
  'loginCode.prolong': 'Förnya loginkod',
  'loginCode.refreshSuccessMessage': 'Loginkod har uppdaterats',
  'loginCode.title': 'Loginkod',
  'loginForm.errors.pnrIsNotValid': 'Använda 12 siffror i ditt personnummer',
  'loginForm.errors.pwdIsInvalid': 'Felaktigt lösenord',
  'logout': 'Logga ut',
  'low': 'Låg',
  'Male': 'Man',
  'male': 'Man',
  'manager': 'Manager',
  'March': 'Mars',
  'Mark as correct': 'Markera som korrekt',
  'Mark as incorrect': 'Markera som felaktigt',
  'mark-as-read': 'Markera som läst',
  'max': 'Max',
  'max_if_all_answered': 'max. om allt besvarat',
  'maxScoreExceeded': 'Maximum score is 59',
  'May': 'Maj',
  'MCI.abbreviationDecoding': 'Lindrig kognitiv funktionsnedsättning',
  'Medicin': 'Medication',
  'medicinNoteRisk': 'Se Socialstyrelsens lista över <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/dokument-webb/ovrigt/aldre-olampliga-lakemedel-for-aldre-lista.pdf" target="_blank">Läkemedel som bör undvikas till äldre</a> samt <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/artikelkatalog/ovrigt/2018-12-37.pdf" target="_blank">Diagnostisk checklista för förbättrad läkemedelsanvändning hos äldre</a>.<br /><br />Läkemedel med antikolinerga effekter: Akineton, Alimemazin, Amitriptylin, Anafrani,l Atarax, Atropin, Buscopan, Calma, Clozapine, Detrusitol, Ditropan, Durbis, Egazil, Emselex, Glykopyrroniumbromid, Hydroxizin, Hydroxyzine, Kentera, Ketogan, Klomipramin, Leponex, Lergigan, Levomepromazine, Ludiomil, Metylskopolamin, Morfin-Skopolamin, Norflex, Norgesic, Nozinan, Oxikodon-hyoscin, Oxybutynin, Pargitan, Postafen, Proklorperazin, Prometazin, Robinul, Saroten, Scopoderm, Sensaval, Sialanar, Spasmofen, Tavegyl, Theralen, Tolterodin, Tolterodine, TOVIAZ, Truxal, Vesicare och Vesikur',
  'medium-heavy-alcohol': 'mellanstark alkohol',
  'Meeting with': 'Mötet med',
  'meetings': 'Möten',
  'Meetings': 'Möten',
  'meetings today': 'Dagens Möten',
  'meetings-page.title': 'Mina möten',
  'Memory': 'Minne',
  'Mental hälsa (PHQ-9)': 'Mental Health/Depression (PHQ-9)',
  'mentalNoteRisk': 'Fråga 9: "Har du tankar kring att det skulle vara bättre om du var död eller att du skulle skada dig på något sätt?" har besvarats positivt. Genomför bedömning av suicidrisk.',
  'Mic': 'Mic',
  'mildRisk': 'Lindrig',
  'Mimic': ' Mimik',
  'min': 'Min',
  'minimalRisk': 'Minimalt eller ingen',
  'minutes': 'minuter',
  'modal.bankId.description': 'För att logga in, starta Mobilt BankID på din enhet',
  'modal.bankId.title': 'Öppna Mobile BankID',
  'modal.confirmation.refreshLoginCode': 'Vill du verkligen utföra den här åtgärden?',
  'moderateRisk': 'Måttlig',
  'moderateSeverRisk': 'Ganska svår',
  'Monday': 'Måndag',
  'Month': 'Månad',
  'Muscle tone': ' Muskeltonus',
  'name': 'Namn',
  'Naming ability': ' Benämningsförmåga',
  'neuropsychologist': 'Neuropsykolog',
  'New password': 'Nytt lösenord',
  'new_physiotherapist': 'Sjukgymnast',
  'newAccount': 'Nytt konto',
  'newPatient': 'Ny patient',
  'next meeting': 'Nästa möte',
  'Next meeting in': 'Nästa möte om',
  'Next meeting with': 'Nästa mötet med',
  'No': 'Nej',
  'No answer': 'Inget svar',
  'No meeting found': 'Hittade inga möten',
  'No more meetings for today': ' Inga fler bokade möten idag',
  'no results': 'Inga resultat',
  'No upcoming meetings': 'Inga bokade möten',
  'no-avail-set-for-this-time': 'Ingen tillgänglighet för denna tid',
  'no-patients': 'Inga patienter finns',
  'no-referrals-currently': 'Det finns inga remisser just nu',
  'Normal': 'Normal',
  'Not completed': 'Inte färdigt',
  'Not Requested': 'Ej Efterfrågad',
  'not_relevant': 'Inte relevant',
  'note': 'Notering',
  'note-form.text': 'Anteckningarna visas enbart för specialister, inte för patienten',
  'note_type_1': 'Det maximala antalet poäng för denna övning är 3 poäng. Alla försök kommer att bedömas',
  'note_type_10': 'Det maximala antalet poäng för denna övning är 2 poäng. Alla försök kommer att bedömas',
  'note_type_11': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_12': 'Det maximala antalet poäng för denna övning är 2 poäng. Alla försök kommer att bedömas',
  'note_type_13': 'Det maximala antalet poäng för denna övning är 6 poäng. Det andra försöket kommer att bedömas.Det första försöket är en testomgång.',
  'note_type_14': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_15': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_16': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_2': 'Det maximala antalet poäng för denna övning är 1 poäng. Endast de bästa försöken kommer att bedömas',
  'note_type_3': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_4': 'Det maximala antalet poäng för denna övning är 1 poäng. Alla svar behöver vara korrekta för att klara 1 poäng.',
  'note_type_5': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla svar behöver vara korrekta för att klara 1 poäng. Endast det första försöket kommer att bedömas.',
  'note_type_6': 'Det maximala antalet poäng för denna övning är 4 poäng. Alla försök kommer att bedömas',
  'note_type_7': 'Det maximala antalet poäng för denna övning är 4 poäng. Alla försök kommer att bedömas',
  'note_type_8': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'note_type_9': 'Det maximala antalet poäng för denna övning är 5 poäng. Endast detta försök kommer att bedömas.',
  'NotEmptyAndValid': 'Fältet måste innehålla en e-postadress',
  'notes': 'Anteckningar',
  'November': 'November',
  'nurse': 'Sjuksköterska',
  'o': 'Orientering',
  'o-description': 'Språk avser din förmåga att förstå och uttrycka muntligt och skriftligt språk och hitta ord samt namn efter kategori eller ljud.',
  'Obesity Class I': 'Fetma Klass I',
  'Obesity Class II': 'Fetma Klass II',
  'Obesity Class III': 'Fetma Klass III',
  'October': 'Oktober',
  'of': 'av',
  'Old style ss': 'Old style ss',
  'Operating speed': ' Rörelsetempo',
  'Opinion': 'Omdöme ',
  'other': 'Övrigt',
  'other-relationship': 'Annan relation',
  'Overweight': 'Övervikt',
  'p-1': 'Ja, uttalat / ofta = 2p',
  'p-2': 'Ja, något / ibland = 1p',
  'p-3': 'Nej = 0p',
  'p-4': 'Vet inte / kan inte bedöma = Inte relevant',
  'parent': 'Förälder',
  'Parietal / Temporal': 'Parietal / Temporal',
  'Parkinson\'s disease with dementia - PDD': 'Parkinsons sjukdom med demens - PDD',
  'partner': 'Partner',
  'Password': 'Lösenord',
  'passwordNote': 'Ditt lösenord måste innehålla minst åtta tecken och innehålla minst 1 specialtecken',
  'past available times': 'Tidigare tillgängliga tider',
  'past meetings': 'Tidigare möten',
  'Patient left test ahead of time': 'Test avbrutet av patient',
  'patient-appointments': 'Patientmöten',
  'patient.note.edit': 'Ändra anteckning',
  'patient.questionnaire-result.completed': 'Färdig',
  'patient.questionnaire-result.not-completed': 'Inte färdigt',
  'patient.questionnaire-result.title': 'Uppgiftshistorik för patientens fördiagnos',
  'patientAccount': 'Patientinformation',
  'patientAccount.newPatient.patientTasks.cognitiveTest': 'Kognitivt test (GSCT)',
  'patientAccount.newPatient.patientTasks.deselectAll': 'Avmarkera alla',
  'patientAccount.newPatient.patientTasks.medicalHistory': 'Anamnes',
  'patientAccount.newPatient.patientTasks.selectAll': 'Välj alla',
  'patientAccount.newPatient.patientTasks.symptomSurvey': 'Symtomenkät',
  'patientEmails': 'Email till patient',
  'Patientens koncentration - ADHD/ADD (ASRS)': 'Patient’s ability to focus - ADHD/ADD (ASRS)',
  'Patientens upplevda symtom': 'Perceived cognitive symptoms',
  'patientForm.errors.emailIsNotValid': 'Email ej giltig',
  'patientForm.errors.pnrIsNotValid': 'Personnummer måste vara 12 siffror',
  'patientInfo.patientStatus.assessmentReviewed': 'Utlåtande granskat',
  'patientInfo.patientStatus.complementaryInfo': 'Komplettering',
  'patientInfo.patientStatus.informationGathering': 'Informationsinsamling',
  'patientInfo.patientStatus.prelAssessment': 'Prel. utlåtande',
  'patientInfo.patientStatus.reportGenerated': 'Rapport genererad',
  'patientInfo.patientStatus.resultsNotReviewed': 'Resultat ej granskat',
  'patientInfo.patientStatus.reviewedResults': 'Granskade resultat',
  'patientPhoneNumber': 'Patients mobilnummer',
  'patientPhoneNumber.lastSentTextMessage': 'Senast skickat sms',
  'patientPhoneNumber.noMobileNumber': 'Inget mobilnummer tillgängligt',
  'patientPhoneNumber.sendLoginCode': 'Loginkod & länk till app',
  'patientPhoneNumber.successMessage': 'Textmeddelandet har nu skickats till patient',
  'patientPhoneNumber.title': 'SMS till patient',
  'patients': 'Patienter',
  'patientsProfile': ' Patientens profil',
  'patientTasks': 'Patientens steg',
  'pdf.age': 'Ålder',
  'pdf.ageLowercase': 'ålder',
  'pdf.anamnesis': 'Anamnes',
  'pdf.cognitive': 'Kognitivt Test',
  'pdf.cognitive.copyright': '*Digital Screening for Cognitive Impairment – A Proof ofConcept Study V. Bloniecki1,2, G. Hagman1,3, M. Ryden3 ,M. Kivipelto1,3,4,5,6, The Journal of Prevention of Alzheimer’s Disease – JPAD, in press Jan 2021,CorrespondingAuthor: Victor Bloniecki, Karolinska Institute, Karolinska University Hospital, Eugeniavägen',
  'pdf.cognitive.header': 'Utlåtande - Digital utredning av kognitiva funktioner',
  'pdf.cognitive.instruction-1': '<b>SCI</b> = Subjektiv kognitiv funktionsnedsättning, testpersonen upplever försämring av minne och andra kognitivafunktioner men testning indikerar inte nedsatt kognitiv förmåga, <b>MCI</b> = Lindrig kognitiv funktionsnedsättning.Med det menas att en viss nedsättning av minnesfunktioner kan ses men den är inte så uttalad att det i nuläget talarför demenssjukdom/kognitiv sjukdom. <b>Misstänkt kognitiv sjukdom</b> = testresultat förenligt med kognitiv funktionsnedsättningtill följd av demenssjukdom men andra möjliga behandlingsbara orsaker måste uteslutas',
  'pdf.cognitive.instruction-1.title': 'Förkortningar',
  'pdf.cognitive.instruction-2': '<b>SCI:</b> &#62; 45.0 poäng, 17:00 – 19:00 min<br/><b>MCI:</b> 35.5 – 45.0 poäng, 19:00 – 24:00 min<br/><b>Misstänkt kognitiv sjukdom:</b> &#60;35.5 poäng, 28:00 – 48:00 min',
  'pdf.cognitive.instruction-2.title': 'Gränsvärden',
  'pdf.createdThrough': 'Skapat genom',
  'pdf.diagnos': 'Diagnos',
  'pdf.initialQuestionnaire': 'Inledande frågor – Testa hjärnhälsan',
  'pdf.main': 'Underlag',
  'pdf.name': 'Namn',
  'pdf.recommendations': 'Rekommendation',
  'pdf.summary': 'Sammanfattat resultat och bedömning',
  'pdf.symptomSurvey': 'Symtomenkäten',
  'pdf.symptomSurvey.allFreeTextAnswers': 'Alla fritextssvar',
  'pdf.symptomSurvey.allMultipleChoice': 'Alla flervalsalternativ',
  'pdf.symptomSurvey.symptomSurveyPage': 'Symtomenkätssidan',
  'pdfReport': 'Generera rapport',
  'personalNumber': 'Personnummer',
  'phone': 'Telefonnummer',
  'physiotherapist': 'Fysioterapeut',
  'Planning': 'Planering ',
  'Planning memory 1': 'Planeringsminne 1',
  'Planning memory 2': 'Planeringsminne 2',
  'Play drawing process': 'Spela upp ritprocess',
  'Please obtain verbal consent from the patient to create an account on their behalf.': 'Vänligen erhåll ett muntligt samtycke från patienten för att skapa ett konto på patientens vägnar.',
  'Please wait': 'Vänligen vänta',
  'Please, click on the image below if you already have an app': 'Var god klicka på klicka på knappen nedanför om du redan har appen',
  'Please, click on the image below to download app': 'Var god klicka på knappen nedanför för att ladda ned appen',
  'pnumberFormat': 'ÅÅÅÅMMDD-XXXX',
  'Points': 'Poäng',
  'policy': 'Sekretessvillkor',
  'Posterior cortical atrophy - PCA': 'Posterior kortikal atrofi - PCA ',
  'Practical ability': ' Praktisk förmåga',
  'previousMeetings': 'Tidigare möten',
  'Primary progressive non-fluid aphasia - PNFA': 'Primär progressiv icke-flytande afasi - PNFA',
  'profile-view-doctor': 'Dr.',
  'profile-view-neuropsychologist': 'Dr.',
  'profile-view-new_physiotherapist': 'SG.',
  'profile-view-nurse': 'Ssk.',
  'profile-view-psychologist': 'Dr.',
  'profile-view-therapist': 'AT',
  'Proximity memory 1': 'Korttidsminne 1',
  'Proximity memory 2': 'Korttidsminne 2',
  'psychologist': 'Psykolog',
  'Question was skipped due to previous question answer': 'Frågan hoppades över på grund av tidigare svar på fråga nr ',
  'questionnaire': 'Frågeformulär',
  'Questionnaire': 'Frågeformulär',
  'Questions': 'Frågor',
  'quick_pick_button': 'Snabbval',
  'radiologist': 'Radiolog',
  'Reaction time': 'Reaktionstid',
  'Recognition ability': ' Igenkänningsförmåga',
  'recommendation': 'Rekommendation',
  'Recording not found': 'Inspelning hittades inte',
  'Recording of answers': 'Inspelning av svar',
  'referrals': 'Remisser',
  'Regional Dysfunktionsprofil Patient': 'Regional Dysfunktionsprofil',
  'Relationship': 'Relation',
  'relationship': 'Välj relation',
  'relativeDegrees': '["Partner","Förälder","Barn","Syskon","Annan relation","Vän"]',
  'Remote memory': 'Långtidsminne',
  'report-header': 'Preliminära resultat',
  'report-header-cognitive': 'Kognitiva funktioner',
  'report-high': 'Hög',
  'report-low': 'Låg',
  'report-tasks': 'Testmoment',
  'reportExport': 'Rapport export',
  'Requested': 'Efterfrågad',
  'requestFamilyPhoneLabel': 'Närståendes mobilnummer',
  'requestFamilySurvey': 'Symtomenkät',
  'Required': 'Obligatorisk',
  'Reset counts': 'Raderade pilar',
  'Reset password': 'Återställ lösenord',
  'Responsiveness': ' Reaktionsförmåga',
  'Restore user': 'Återställ användare',
  'restoreAccount': 'Återställ konto',
  'restorePatient': 'Återställ patient',
  'restorePWDForm.errors.emailIsInvalid': 'Felaktig e-post',
  'Results': 'Resultat',
  'Results for Review': 'Tillgänglig',
  'riskIndicator': 'Riskindikator',
  'riskIndicators': 'Riskindikatorer',
  'riskInterval': 'Riskindikator',
  'riskLegend': 'Notering: Se klinisk studie under "Dokument" för mer info',
  'role': 'Roll',
  'Room orientation': ' Rumsorientering',
  'Saturday': 'Lördag',
  'save': 'Spara',
  'save-and-send': 'Spara och skicka',
  'SCI.abbreviationDecoding': 'Subjektiv kognitiv funktionsnedsättning',
  'search': 'Sök',
  'search.results': 'Resultat',
  'searchPatient': 'Sök patient...',
  'seconds': 'sekunder',
  'Select profile': 'Välj profil',
  'Select the recommended path': 'Välj rekommendation för nästa steg',
  'select-all-and-save': 'Välj allt och spara',
  'Select-discount': 'Select discount',
  'selectCommentType': 'Välj typ av kommentar',
  'selectCountry': 'Välj land',
  'selectGender': 'Välj kön',
  'selectLanguage': 'Välj språk',
  'selectRole': 'Välj roll',
  'Semantic dementia - SD': 'Semantisk demens - SD',
  'send': 'Skicka',
  'Send': 'Skicka',
  'Send symptom survey to relative': 'Skicka symtomenkät till närstående',
  'Sending email...': 'Skickar email...',
  'sendSymptomSurveySuccessMessage': 'Symtomenkät skickad',
  'sendToPatientModalBtn': 'Dela med patient',
  'sentToPatient': 'skickad till patient',
  'September': 'September',
  'setNewPassword': 'Bekräfta lösenord',
  'Severely underweight': 'Allvarlig undervikt',
  'severRisk': 'Mycket Hög',
  'Shape sense': ' Formsinne',
  'show preliminary results': 'Visa preliminära resultat till patienter',
  'show_all_records': 'Visa allt',
  'showArchivedTests': 'Se arkiverade tester',
  'showNewTests': 'Återgå till nya tester',
  'sibling': 'Syskon',
  'sidePanel.navigation.contact': 'Kontakt',
  'Sleep disturbance': ' Sömnstörning',
  'specialist': 'Specialist',
  'specialistComments': 'Kommentarer',
  'specialistReviewing': 'Visningshistorik',
  'Speech clarity': ' Taltydlighet',
  'speech_therapist': 'Logoped',
  'start': 'Starta',
  'startPage.button.createPatient': 'Skapa patient',
  'startPage.searchHint': 'Sök patient',
  'starts-in': 'startar om',
  'status': 'Status',
  'stm': 'Minne',
  'stm-description': 'Korttidsminne avser din förmåga att hålla en liten mängd information i åtanke under en kort tid.',
  'Stress sensitivity': ' Stresskänslighet',
  'stressNoteRisk': 'Den som får över 18 poäng kan vara i riskzonen för alternativt vara drabbad av utmattningssyndrom. Då rekommenderas det att patienten tar kontakt med sin vårdcentral/företagshälsovård eller chef för att diskutera testresultatet. Någon finare uppdelning av antalet poäng har forskarna inte gjort.',
  'Subcortical': 'Subkortikal',
  'summary_results_and_assessment': 'Sammanfattat resultat och bedömning',
  'Sunday': 'Söndag',
  'superAdmin': 'Super admin',
  'survey': 'Enkät',
  'Suspected': 'Misstänkt',
  'sv': 'Svenska',
  'symptom survey': 'Symtomenkät',
  'Symptom Survey for relatives': 'Symtomenkät för anhöriga',
  'symptom_survey.riskLegend.hint': 'Summerad poäng visas i relation till de frågor som är besvarade av anhörig. Höga poäng indikerar flera områden med försämrad kognitiv status. Svar ”Vet inte / kan inte bedöma” räknas inte med. Maximal poäng då samtliga frågor besvaras visas inom parentes.',
  'SymptomSurvey_version': 'Symtomenkäten',
  'systolic-pressure': 'Mitt vanliga systoliska tryck',
  'termsAndConditions': 'Användarvillkor',
  'Test duration': 'Testtid',
  'testSettings': 'Application tests settings',
  'testStatus.abortedOrError': 'Avbrutet',
  'testStatus.done': 'Resultat tillgängliga',
  'testStatus.initiated': 'Påbörjat',
  'testStatus.notSent': 'Ej aktiverat',
  'testStatus.sent': 'Aktiverat',
  'testStatus.viewed': 'Granskat',
  'testStatus.viewedWithError': 'Avbrutet / Granskat',
  'Text chat': 'Textchatt',
  'text-chat': 'Textchatt',
  'Text-message history': 'SMS historik',
  'textMessageToPatient.BrainHealth': 'Hjärnhälsa',
  'textMessageToPatient.BrainHealth.messageExample': 'Via denna länk kan du ta del av nyttig information kring hjärnhälsa:</br>https://www.minnesmottagningen.se/hall-hjarnan-frisk/hallhjarnanfrisk </br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.FeedBack': 'Begär feedback',
  'textMessageToPatient.FeedBack.messageExample': 'Det vore värdefullt för oss om du ville svara på några korta frågor om din kontakt med oss. Frågorna tar max 2 minuter att besvara, svaren är helt anonyma.</br>Du svarar på frågorna genom att klicka på denna länk:</br>https://forms.gle/1vvzJU5Z6qfidV9J7</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.loginCode.messageExample': 'Ladda ned app - minnesmottagningen.se/app</br>Logga in med kod: XXXXX</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.MissedAppointment': 'Missat videomöte',
  'textMessageToPatient.MissedAppointment.messageExample': 'Du har uteblivit från ditt bokade videomöte idag.</br>För att undvika ytterligare debitering, vänligen kontakta oss för ombokning av din mötestid.</br>Du når oss på 010-750 07 26 mån-fre kl. 09-15 eller via  info@minnesmottagningen.se.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.NoFreeCard': 'Inget gratiskort - betalningslänk',
  'textMessageToPatient.NoFreeCard.messageExample': 'Utebliven patientavgift.</br>Vid bokning av tid har frikort felaktigt angivits.</br>Vi ber dig därför betala patientavgift på 300 kronor via följande länk:</br>https://buy.stripe.com/4gw17fa4r8eG8ScfZ3</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.RefundForPatient': 'Återbetalning till patient - på grund av frikort',
  'textMessageToPatient.RefundForPatient.messageExample': 'Återbetalning av patientavgift.</br>Vi kommer att göra en återbetalning av patientavgiften på 300 kronor eftersom det vid vår kontroll visade sig att du har giltigt frikort.</br>Observera att det kan ta några dagar innan återbetalningen syns på den betalmetod du valde vid bokning av din tid.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.ReminderForMeeting': 'Mötespåminnelse',
  'textMessageToPatient.ReminderForMeeting.messageExample': 'Mötespåminnelse.</br>Ditt videomöte börjar alldeles strax.</br>Logga in här för att starta videomötet:</br>Minnesmottagningen.se/patientportal</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.ReviewedByDoctor': 'Analyserat av läkare',
  'textMessageToPatient.ReviewedByDoctor.messageExample': 'Dina svar och ditt testresultat har nu granskats av läkare.</br>Resultat, bedömning och rekommendation får du på ditt inbokade videomöte.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.TestReminder': 'Testpåminnelse',
  'textMessageToPatient.TestReminder.messageExample': 'Behov av komplettering.</br>Det finns ofullständiga moment som behöver kompletteras. Logga in i appen för att göra klart de delar som kvarstår.</br> www.Minnesmottagningen.se/app </br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.ThankYou': 'Slutförd minnesutredning',
  'textMessageToPatient.ThankYou.messageExample': 'Information finns nu att hämta i patientportalen via följande länk:</br>www.minnesmottagningen.se/patientportal</br>Obs! Du kan inte svara på detta sms',
  'textMessageToPatient.TriedToReachOut': 'Försökte nå ut',
  'textMessageToPatient.TriedToReachOut.messageExample': 'Vi har försökt nå dig.</br>Vänligen kontakta oss på info@minnesmottagningen.se eller ring 010-750 07 26 mån-fre kl. 9-15.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'Thank you for choosing Geras Solutions!': 'Tack för att du har valt Geras Solutions!',
  'The email to the patient has now been sent': 'Email har nu skickats',
  'therapist': 'Arbetsterapeut',
  'Thought tempo': ' Tanketempo',
  'threeDots': '...',
  'Thursday': 'Torsdag',
  'Time perception 1': 'Tidsuppfattning 1',
  'Time perception 2': 'Tidsuppfattning 2',
  'Time to take test': 'Tidsåtgång för test',
  'time-used': 'Tid för att bli klar',
  'title': 'Patientinformation',
  'To confirm invitation press button below': 'Klicka på inbjudan nedanför för att bekräfta inbjudan',
  'Today': 'Idag',
  'today': 'idag',
  'Tomorrow': 'Imorgon',
  'tomorrow': 'imorgon',
  'Total points': 'Poängfördelning',
  'Total time': 'Total tid',
  'totalPatients.counter.plural': 'patienter',
  'totalPatients.counter.singular': 'patient',
  'transcription from': 'Original transkription',
  'Transcription of answers': 'Transkription',
  'transcription to': 'Ny transkription',
  'try': 'Försök',
  'Tuesday': 'Tisdag',
  'Type here...': 'Skriv här...',
  'Type to search patient': 'Skriv för att söka efter patient',
  'Typical authentic patient cases, not scientifically validated': 'Typiska autentiska patientfall, ej vetenskapligt validerade',
  'Underweight': 'Undervikt',
  'upcomingMeetings': 'Kommande möten',
  'username': 'Användarnamn',
  'va': 'Visuospatial förmåga',
  'va-description': 'Visuospatial förmåga avser din förmåga att identifiera objekt i ett utrymme och hur de relaterar till utrymmet.',
  'Vascular dementia - VaD': 'Vaskulär demens - VaD',
  'Very severely underweight': 'Mycket allvarlig undervikt',
  'video-appointment': 'Videosamtal',
  'video-call': 'Videosamtal',
  'voucherCode': 'Generate Voucher Code',
  'voucherCodeStats': 'Voucher Code Statistic',
  'Waiting for user to join...': 'Väntar på att användare ska ansluta...',
  'Walking pattern': ' Gångmönster',
  'We have e-mailed your password reset link': 'Om ditt konto finns i vårt system kommer du att få ett e-postmeddelande för att återställa lösenordet.',
  'We strongly recommend do not reload the page.': 'Vi rekommenderar starkt att du inte laddar om sidan.',
  'Wednesday': 'Onsdag',
  'Week': 'Vecka',
  'weight': 'Vikt',
  'welcome': 'Välkommen',
  'welcome_geras_1': 'Välkommen till Geras Solutions',
  'welcome_geras_2': 'Vårdgivarportal',
  'Why did you download Geras app?': 'Varför laddade du ner Geras-appen?',
  'whyGeras': 'Varför laddade du ner Geras appen?',
  'will start in': 'börjar om',
  'with': 'med',
  'wm': 'Uppmärksamhet, koncentration och arbetsminne',
  'wm-description': 'Dessa är relaterade till ditt korttidsminne och avser din förmåga att manipulera och organisera information som lagras i ditt korttidsminne.',
  'words:': 'Ord: ',
  'Write patient’s name, personal number, or email': 'Skriv patientens namn, personnummer eller patient-id',
  'Write your message here': 'Skriv ditt meddelande här',
  'Write your opinion, which will be sent to the patient': 'Skriv din åsikt, vilken kommer att skickas till patienten',
  'writeComment': 'Skriv en kommentar',
  'Writing ability': ' Skrivförmåga',
  'years': 'år',
  'yearsOld': 'år',
  'Yes': 'Ja',
  'You can restore this user later': 'Du kan återställa användare senare',
  'You successfully changed your password': 'Du har ändrat ditt lösenord',
  'zh': '中文',
};
 export default trans;